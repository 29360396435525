
/* Succes Style */
.Toastify__progress-bar--success {
    background: #3836B4;
  }

/* Warna teks ikon */
/* .Toastify__toast--success .Toastify__toast-body {
    color: #8C52FF; 
  } */
  
  /* Warna ikon */
/* .Toastify__toast--success .Toastify__icon {
    color: #8C52FF; 
} */


/* Error Style */
/* Ganti background dengan warna latar belakang yang Anda inginkan */
.react-toastify .Toastify__toast--error {
  background-color: #3836B4 !important;
  font-weight: bold;
}