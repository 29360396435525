@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap');

* {
  font-family: 'Jakarta Sans', sans-serif;
  
}

body{
  margin: 0;
  background-color: #E6ECFC;
}